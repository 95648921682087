import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  VStack,
  chakra,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateAuthToken } from 'api/auth';
import { queryClient } from 'config/query-client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'router';
import { useUserStore } from 'store';
import { z } from 'zod';

const validationSchema = z.object({
  email: z.string({ required_error: 'Required' }).email('Must be a valid email'),
  password: z.string({ required_error: 'Required' }).nonempty({ message: 'Required' }),
});

type FormValues = z.infer<typeof validationSchema>;

export default function () {
  const location = useLocation();
  const methods = useForm<FormValues>({
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
    defaultValues: { email: location.state?.email || '', password: '' },
  });
  const { handleSubmit, register, formState } = methods;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const createToken = useCreateAuthToken({});
  const navigate = useNavigate();
  const { patch } = useUserStore();

  const passwordIcon = () => {
    if (showPassword) {
      return <Icon cursor="pointer" onClick={() => setShowPassword(false)} as={FiEyeOff} />;
    }
    return <Icon cursor="pointer" onClick={() => setShowPassword(true)} as={FiEye} />;
  };

  const onSubmit = async ({ ...values }: FormValues) => {
    createToken.mutate(values, {
      onSuccess: async (data) => {
        queryClient.invalidateQueries();

        if (!data.user?.isPhoneVerified) {
          navigate('/phone-validation', {
            state: {
              user: data.user,
              updatePhoneToken: data.updatePhoneToken,
            },
          });
        }

        if (data.token) {
          patch({ authToken: data.token, lastLoginAt: new Date().toISOString() });
          navigate('/ship');
        }
      },
    });
  };

  return (
    <chakra.form onSubmit={handleSubmit((e) => onSubmit(e))}>
      <VStack spacing={3.5}>
        <FormControl isRequired isInvalid={!!formState.errors.email}>
          <FormLabel>Email</FormLabel>
          <Input placeholder=" " {...register('email')} />
          <FormErrorMessage>{formState?.errors?.email?.message}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!formState.errors.password}>
          <FormLabel>Password</FormLabel>
          <InputGroup>
            <Input placeholder=" " type={showPassword ? 'text' : 'password'} {...register('password')} />
            <InputRightElement color="muted" mr={2}>
              {passwordIcon()}
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{formState?.errors?.password?.message}</FormErrorMessage>
        </FormControl>
        <Button
          type="submit"
          colorScheme="brand"
          isDisabled={createToken.isLoading || !formState.isValid}
          isLoading={createToken.isLoading}
          loadingText="Signing In..."
          w="100%"
          id="sign-in-button"
        >
          Sign In
        </Button>
      </VStack>
    </chakra.form>
  );
}
