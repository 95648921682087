import { Box, Divider, Flex, HStack, Heading, SimpleGrid, Stack, Text } from '@chakra-ui/layout';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  FormControl,
  Icon,
  Image,
  Input,
  Select,
  Stat,
  StatLabel,
  StatNumber,
  useDisclosure,
} from '@chakra-ui/react';
import { Spinner } from '@chakra-ui/spinner';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetQuery, usePostMutation } from 'api/client';
import { PageBody, PageHeader } from 'components/Page';
import { PrintPDFLabels } from 'components/print-label-modal/print-pdf-labels';
import { useNotification } from 'contexts/notification.context';
import { useBarcodeScan } from 'hooks/use-barcode-scan';
import { orderBy, sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  LuCheckCircle2,
  LuCircleDollarSign,
  LuHome,
  LuPencil,
  LuPrinter,
  LuScan,
  LuTruck,
} from 'react-icons/lu';
import { useNavigate, useParams } from 'router';
import { ouncesToPounds } from 'utils/misc';

type LineItem = {
  sku: string;
  name: string;
  quantity: number;
  imageUrl: string | null;
  weightOz: number | null;
};

const LineItemsList = ({ lineItems }: { lineItems: LineItem[] }) => {
  return (
    <Stack divider={<Divider />}>
      {lineItems.map((item, index) => (
        <HStack key={index}>
          <Box bg="zinc.100" w="16" h="16" rounded="lg" overflow="hidden">
            {item.imageUrl && <Image src={item.imageUrl || ''} />}
          </Box>

          <Stack spacing={1}>
            <Text fontWeight="medium">{item.name}</Text>
            <Text fontSize="sm" color="zinc.400">
              SKU {item.sku}
            </Text>
          </Stack>

          <Flex px={6} ml="auto" fontWeight={'semibold'}>
            <Text fontSize="lg">{item.quantity}</Text>
          </Flex>
        </HStack>
      ))}
    </Stack>
  );
};

import { z } from 'zod';

const AddressSchema = z.object({
  line1: z.string(),
  line2: z.string().optional(),
  city: z.string(),
  state: z.string(),
  postal: z.string(),
  company: z.string().optional(),
  phone: z.string().optional(),
  name: z.string().optional(),
  email: z.string().optional(),
  country: z.string().optional().default('US'),
  residential: z.boolean().optional(),
});

export const schema = z.object({
  orderId: z.number().optional().nullable(),
  from: AddressSchema,
  to: AddressSchema,
  reference: z.string().optional(),
  parcel: z.object({
    length: z.coerce.number().min(0.1).nullable(),
    width: z.coerce.number().min(0.1).nullable(),
    height: z.coerce.number().min(0.1).nullable(),
    weight: z.coerce.number().describe('weight in ounces').optional(),
    predefinedPackage: z.string().nullable(),
  }),
});

export type FormValues = z.infer<typeof schema>;

export default function () {
  const params = useParams('/orders/:id/pack');
  const navigate = useNavigate();

  const order = useGetQuery('/api/v3/orders/{id}', { params: { path: { id: params.id } } });

  const [scannedItems, setScannedItems] = useState<LineItem[]>([]);
  const [lineItems, setLineItems] = useState<LineItem[]>([]);
  const [overrideWeight, setOverrideWeight] = useState<boolean>(false);
  const [selectedRate, setSelectedRate] = useState<string | null>(null);

  const printModal = useDisclosure();

  const notify = useNotification();

  const createShipment = usePostMutation('/api/v4/shipments');
  const buyShipment = usePostMutation('/api/v3/shipments/buy');
  const addresses = useGetQuery('/api/v3/origin-addresses', {});
  const primary = addresses.data?.results?.find((a) => a.primary) || addresses.data?.results?.[0];

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    disabled: buyShipment.isSuccess,
    values: {
      // @ts-expect-error
      to: undefined,
      from: {
        company: primary?.company ?? '',
        name: primary?.name ?? '',
        line1: primary?.line1 ?? '',
        line2: primary?.line2 ?? undefined,
        city: primary?.city ?? '',
        state: primary?.state ?? '',
        postal: primary?.postal ?? '',
        country: primary?.country ?? 'US',
      },
      parcel: {
        weight: order?.data?.parcel?.weight ?? undefined,
        width: order?.data?.parcel?.width ?? null,
        height: order?.data?.parcel?.height ?? null,
        length: order?.data?.parcel?.length ?? null,
        predefinedPackage: order?.data?.parcel?.predefinedPackage ?? null,
      },
    },
  });

  const handleCreateShipment = async () => {
    const data = form.getValues();
    const itemsWeightOz = sumBy(scannedItems, (item) => (item.weightOz || 0) * item.quantity);

    const weight = overrideWeight ? data.parcel.weight : itemsWeightOz;
    const isValidWeight = !!weight && weight > 0;

    if (!isValidWeight) {
      setOverrideWeight(true);
      form.setError('parcel.weight', { message: 'Required' });
      return;
    }

    const parcel = {
      length: data.parcel.length,
      width: data.parcel.width,
      height: data.parcel.height,
      weight: overrideWeight ? data.parcel.weight! : itemsWeightOz,
      predefinedPackage: null,
    };

    const body = {
      reference: order?.data?.reference,
      orderId: order.data?.id,
      from: data.from,
      to: data.to,
      parcels: [parcel],
      options: (order.data?.options as any) || {},
    };

    createShipment.mutate({ body });
  };

  const handleBuyLabel = async () => {
    const shipmentId = createShipment.data?.id;
    const rateId = selectedRate;
    const orderId = order.data?.id;

    if (!shipmentId || !rateId || !orderId) return;

    buyShipment.mutate(
      { body: { rateId, shipmentId, orderId } },
      {
        onSuccess: () => {
          notify.success('Label purchased successfully');
          printModal.onOpen();
        },
      },
    );
  };
  // Scan barcodes and store them in the scannedCodes state
  useBarcodeScan((code) => {
    const items = new Map<string, LineItem>(lineItems.map((i) => [i.sku, i]));
    const scanned = new Map<string, LineItem>(scannedItems.map((i) => [i.sku, i]));

    const item = items.get(code);

    // Barcode not found in the line items
    if (!item) return;

    // If there's only one item left, remove it from the line items
    if (item.quantity === 1) items.delete(code);
    // If there's multiple items left, update quantity
    else item.quantity -= 1;

    const scannedItem = scanned.get(code);

    // If the item is already scanned, update quantity
    if (scannedItem) scannedItem.quantity += 1;
    // If the item is not scanned, add it to the scanned items
    else scanned.set(code, { ...item, quantity: 1 });

    // Update the line items and scanned items
    setLineItems(Array.from(items.values()));
    setScannedItems(Array.from(scanned.values()));
  });

  useEffect(() => {
    if (!order.data) return;

    // Update line items when order data is loaded
    const items = order.data?.lineItems?.map((i) => ({
      sku: i.product.sku,
      name: i.product.name,
      quantity: i.quantity,
      imageUrl: i.product.imageUrl,
      weightOz: i.product.weightOz,
    }));

    setLineItems(items || []);

    // Set form fields
    const { line1, line2, city, state, company, postal, phone, name, email, country } =
      order?.data?.shippingAddress;
    const { reference } = order.data || {};

    if (company) form.setValue('to.company', company);
    if (name) form.setValue('to.name', name);
    if (line1) form.setValue('to.line1', line1);
    if (line2) form.setValue('to.line2', line2);
    if (city) form.setValue('to.city', city);
    if (state) form.setValue('to.state', state);
    if (postal) form.setValue('to.postal', postal);
    if (phone) form.setValue('to.phone', phone);
    if (email) form.setValue('to.email', email);
    if (country) form.setValue('to.country', country);
    if (reference) form.setValue('reference', reference);
  }, [order.data]);

  // Set a default rate if the order has Service and Carrier set.
  useEffect(() => {
    if (createShipment.data && order.data?.selectedRate) {
      const rates = createShipment.data.rates;
      const carrier = order.data?.carrier;
      const service = order.data?.service;

      const selected = rates.find((r) => r.service === service && r.carrier === carrier);

      if (selected) setSelectedRate(selected.id);
    }
  }, [order.data?.selectedRate, createShipment.isSuccess]);

  // Reset the createShipment mutation if any form field changes
  useEffect(() => {
    const subscription = form.watch((_value, { type }) => {
      if (createShipment.isSuccess && type === 'change') {
        createShipment.reset();
      }
    });

    return () => subscription.unsubscribe();
  }, [form, createShipment]);

  if (!order.data) return <Spinner />;

  const allItemsScannedMarkup = scannedItems.length > 0 && lineItems.length === 0 && (
    <Flex direction="column" align="center" justify="center" py={24}>
      <Icon as={LuCheckCircle2} boxSize={8} color="green.500" mb={3} />
      <Text color="zinc.600" fontSize="md" mb={1}>
        All items scanned
      </Text>
      <Text color="zinc.500" UPPPER-STRAPS-UMBER fontSize="sm">
        You can now prepare this order for shipping
      </Text>
    </Flex>
  );

  const to = order.data?.shippingAddress;
  const from = form.getValues('from');
  const rates = createShipment.isSuccess ? orderBy(createShipment.data?.rates, 'price', 'asc') : null;

  const totalWeight = order?.data?.parcel?.weight
    ? // Use the initial order weight if it's present
      ouncesToPounds(order?.data?.parcel?.weight)
    : // Calculate the weight from the scanned items
      ouncesToPounds(sumBy(scannedItems, (item) => (item.weightOz || 0) * item.quantity));

  return (
    <Box>
      <PageHeader>
        <Heading as="h1">Order {order.data?.platformId}</Heading>

        <HStack ml="auto">
          <Button
            variant="outline"
            gap="2"
            hidden={buyShipment.isSuccess}
            onClick={() => {
              navigate('/ship', { state: { order: { id: order.data?.id } } });
            }}
          >
            <LuTruck />
            Open in Quick Ship
          </Button>
          <Button variant="outline" gap="2" hidden={!buyShipment.isSuccess} onClick={printModal.onOpen}>
            <LuPrinter />
            Print Label
          </Button>
        </HStack>
      </PageHeader>

      <PageBody>
        <SimpleGrid columns={2} spacing={4}>
          <Card minH="3xl">
            <CardHeader>
              <Text fontSize="lg" fontWeight="medium">
                Items
              </Text>
            </CardHeader>
            <Divider />
            <CardBody>
              {lineItems.length > 0 && <LineItemsList lineItems={lineItems} />}
              {allItemsScannedMarkup}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <HStack justify="space-between">
                <Text fontSize="lg" fontWeight="medium">
                  Pack & Ship
                </Text>

                <Button gap={2} variant="outline" size="xs">
                  <LuHome /> {from?.company || from?.line1}
                </Button>
              </HStack>
            </CardHeader>
            <Divider />
            <CardBody>
              <form id="create-shipment-form" onSubmit={form.handleSubmit(handleCreateShipment)}>
                <HStack justify="space-between">
                  <Stack spacing={1}>
                    <Text fontWeight="medium">{to?.name}</Text>
                    <Text hidden={!to?.company}>{to?.company}</Text>
                    <Text>{to?.line1}</Text>
                    <Text hidden={!to?.line2}>{to?.line2}</Text>
                    <Text>
                      {to?.city} {to?.state} {to?.postal}, {to.country}
                    </Text>
                    <Text hidden={!to?.phone}>{to?.phone}</Text>
                    <Text hidden={!to?.email}>{to?.email}</Text>
                  </Stack>

                  <Flex pl={6}>
                    <Stat>
                      <HStack spacing={0.5}>
                        <StatLabel ml="auto">Weight</StatLabel>
                        <Button variant="ghost" size="xs" onClick={() => setOverrideWeight(!overrideWeight)}>
                          <LuPencil />
                        </Button>
                      </HStack>
                      <StatNumber hidden={overrideWeight}>{totalWeight.toFixed(2)} lbs</StatNumber>
                      <FormControl isInvalid={!!form.formState.errors?.parcel?.weight}>
                        <Input
                          maxW="32"
                          defaultValue={totalWeight}
                          hidden={!overrideWeight}
                          placeholder="Weight"
                          {...form.register('parcel.weight')}
                        />
                      </FormControl>
                    </Stat>
                  </Flex>
                </HStack>

                <Stack mt={6}>
                  <Text fontSize={'md'} fontWeight="medium">
                    Package
                  </Text>

                  <HStack>
                    <FormControl isInvalid={!!form.formState.errors?.parcel?.length}>
                      <Input placeholder="Length" {...form.register('parcel.length')} />
                    </FormControl>
                    <FormControl isInvalid={!!form.formState.errors?.parcel?.width}>
                      <Input placeholder="Width" {...form.register('parcel.width')} />
                    </FormControl>
                    <FormControl isInvalid={!!form.formState.errors?.parcel?.height}>
                      <Input placeholder="Height" {...form.register('parcel.height')} />
                    </FormControl>
                  </HStack>
                </Stack>

                <Stack mt={6}>
                  <Text fontSize={'md'} fontWeight="medium">
                    Items
                  </Text>
                  {scannedItems.length === 0 && (
                    <Flex
                      border="1px dashed"
                      borderColor="zinc.300"
                      rounded="lg"
                      direction="column"
                      align="center"
                      justify="center"
                      py={24}
                    >
                      <Icon as={LuScan} boxSize={8} color="zinc.600" mb={3} />
                      <Text color="zinc.900" fontSize="sm" mb={1}>
                        No items scanned yet
                      </Text>
                      <Text color="zinc.500" fontSize="sm">
                        Scan items to start packing
                      </Text>
                    </Flex>
                  )}

                  {scannedItems.length > 0 && <LineItemsList lineItems={scannedItems} />}
                </Stack>
              </form>
            </CardBody>

            <Divider />

            <CardFooter>
              <HStack w="full" hidden={!rates}>
                <Select
                  w="60"
                  isDisabled={buyShipment.isSuccess}
                  onChange={(e) => setSelectedRate(e.target.value)}
                  value={selectedRate || ''}
                >
                  <option value="">Select a rate</option>
                  {rates?.map((rate) => (
                    <option key={rate.id} value={rate.id}>
                      ${rate.price} - {rate.carrierReadable} {rate.service}
                    </option>
                  ))}
                </Select>
                <Button
                  type="button"
                  ml="auto"
                  colorScheme="brand"
                  onClick={handleBuyLabel}
                  isLoading={buyShipment.isLoading}
                  isDisabled={buyShipment.isSuccess || !selectedRate}
                >
                  Buy & Print Label
                </Button>
              </HStack>

              <HStack w="full" alignItems="center" hidden={!!rates}>
                <Button
                  isLoading={createShipment.isLoading}
                  form="create-shipment-form"
                  colorScheme="brand"
                  type="submit"
                  mx="auto"
                  gap="2"
                >
                  <LuCircleDollarSign />
                  Get Shipping Rates
                </Button>
              </HStack>
            </CardFooter>
          </Card>
        </SimpleGrid>
      </PageBody>

      {buyShipment.isSuccess && printModal.isOpen && (
        <PrintPDFLabels {...printModal} shipmentIds={[buyShipment.data?.id || -1]} />
      )}
    </Box>
  );
}
