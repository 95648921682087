import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Select,
  Stack,
} from '@chakra-ui/react';
import { ApiResult, usePutMutation } from 'api/client';
import { useNotification } from 'contexts/notification.context';
import { useForm } from 'react-hook-form';

interface EditBatchModalProps extends Omit<ModalProps, 'children'> {
  batch: ApiResult<'get', '/api/v3/batch/{id}'>;
}

export function EditBatchModal(props: EditBatchModalProps) {
  const { batch, ...rest } = props;

  const form = useForm({
    values: {
      name: batch.displayName,
      sorting: batch.sorting || 'order_number',
      sortingDirection: batch.sortingDirection || 'asc',
    },
  });

  const notify = useNotification();

  const editBatch = usePutMutation('/api/v3/batch/{id}');

  const onSubmit = form.handleSubmit((values) => {
    const valid = [values.sorting, values.sortingDirection].filter(Boolean);

    const body = {
      name: values.name,
      sorting: values.sorting,
      sortingDirection: (values.sortingDirection as 'asc' | 'desc' | null) || 'asc',
    };
    editBatch.mutate(
      { params: { path: { id: String(batch.id) } }, body },
      { onSuccess: () => (rest.onClose(), notify.success('Batch updated successfully')) },
    );
  });

  return (
    <Modal {...rest}>
      <ModalOverlay />
      <form onSubmit={onSubmit}>
        <ModalContent>
          <ModalHeader>Edit Batch</ModalHeader>
          <ModalBody>
            <Stack spacing={3.5}>
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input {...form.register('name')} />
              </FormControl>

              <FormControl>
                <FormLabel>Sorting</FormLabel>
                <HStack>
                  <Select {...form.register('sorting')}>
                    <option value="order_number">Order #</option>
                    <option value="location">Location</option>
                    <option value="status">Status</option>
                  </Select>
                  <Select {...form.register('sortingDirection')}>
                    <option value="asc">Ascending ↑</option>
                    <option value="desc">Descending ↓</option>
                  </Select>
                </HStack>
              </FormControl>
            </Stack>
          </ModalBody>
          <ModalFooter>
            <Button type="submit" colorScheme="brand">
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
