import { Card, CardBody, Heading } from '@chakra-ui/react';
import { PageBody, PageHeader } from 'components/Page';
import { useParams } from 'router';
import { StorePlatform } from 'types/enums';
import { ConnectEbay2Form } from '../_components/connect-ebay-2-form';
import { ConnectEbayForm } from '../_components/connect-ebay-form';
import ConnectEtsyForm from '../_components/connect-etsy-form';
import ConnectExtensivForm from '../_components/connect-extesiv-form';
import { ConnectShipstationForm } from '../_components/connect-shipstation-form';
import ConnectShopifyForm from '../_components/connect-shopify-form';
import { ConnectWooCommerceForm } from '../_components/connect-woocommerce-form';
import { ConnectAmazonForm } from '../_components/connect-amazon-form';
import { ConnectWalmartForm } from '../_components/connect-walmart-form';

export default function () {
  const { platform } = useParams('/stores/:platform/connect');

  return (
    <>
      <PageHeader>
        <Heading as="h1">Connect Store</Heading>
      </PageHeader>
      <PageBody>
        <Card maxW="container.lg">
          <CardBody>
            {platform === StorePlatform.Ebay ? <ConnectEbayForm /> : null}
            {platform === StorePlatform.Shopify ? <ConnectShopifyForm /> : null}
            {platform === StorePlatform.Etsy ? <ConnectEtsyForm /> : null}
            {platform === StorePlatform.WooCommerce ? <ConnectWooCommerceForm /> : null}
            {platform === StorePlatform.Ebay2 ? <ConnectEbay2Form /> : null}
            {platform === StorePlatform.ShipStation ? <ConnectShipstationForm /> : null}
            {platform === StorePlatform.Extensiv ? <ConnectExtensivForm /> : null}
            {platform === StorePlatform.Amazon ? <ConnectAmazonForm /> : null}
            {platform === StorePlatform.Walmart ? <ConnectWalmartForm /> : null}
          </CardBody>
        </Card>
      </PageBody>
    </>
  );
}
