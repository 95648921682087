// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client';

export type Path =
  | `/`
  | `/accept-invitation`
  | `/addresses`
  | `/automations`
  | `/billing`
  | `/boxes`
  | `/edit-phone`
  | `/inventories`
  | `/inventories/analytics`
  | `/inventories/locations/:id`
  | `/inventories/receiving`
  | `/inventories/receiving/:poId`
  | `/login`
  | `/orders`
  | `/orders/:id/pack`
  | `/orders/batches`
  | `/orders/batches/:id`
  | `/orders/files/:id/mapping`
  | `/orders/import`
  | `/orders/import/:id`
  | `/phone-validation`
  | `/products`
  | `/products/import`
  | `/products/new`
  | `/register`
  | `/reporting`
  | `/reset-password`
  | `/settings`
  | `/ship`
  | `/shipments`
  | `/shipments/:id`
  | `/shipments/helpers`
  | `/stores`
  | `/stores/:platform/:id`
  | `/stores/:platform/connect`
  | `/update-password`;

export type Params = {
  '/inventories/locations/:id': { id: string };
  '/inventories/receiving/:poId': { poId: string };
  '/orders/:id/pack': { id: string };
  '/orders/batches/:id': { id: string };
  '/orders/files/:id/mapping': { id: string };
  '/orders/import/:id': { id: string };
  '/shipments/:id': { id: string };
  '/stores/:platform/:id': { platform: string; id: string };
  '/stores/:platform/connect': { platform: string };
};

export type ModalPath = never;

export const { Link, Navigate } = components<Path, Params>();
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>();
export const { redirect } = utils<Path, Params>();
