import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  chakra,
} from '@chakra-ui/react';
import { getShipmentLabelTypes } from 'api/labels';
import useCreateLabelPDF, { CreateLabelPDFResponse } from 'api/shipments/label-pdf';
import { chunk } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { LuDownload } from 'react-icons/lu';
import { useQuery } from 'react-query';
import { useQuickShipStore } from 'store';
import { stopPropagate } from 'utils/misc';
import { PrintLabelModalProps } from './types';

interface FormValues {
  format: string;
  isDefault: boolean;
  packingList: boolean;
}

const PRINT_CHUNK_SIZE = 501;

export function PrintPDFLabels({ isOpen, onClose, shipmentIds, title, onSuccess }: PrintLabelModalProps) {
  const [ids, setIds] = useState<number[]>([]);
  const [results, setResults] = useState<string[]>([]);

  const { printFormat, patch } = useQuickShipStore();

  const { data, isLoading } = useQuery(
    ['label_types', shipmentIds],
    () => getShipmentLabelTypes(shipmentIds.filter(Boolean).map((id) => id.toString())),
    {
      onError: () => onClose(),
      enabled: isOpen,
      retry: false,
    },
  );

  const { formats: labelOptions, isPackingListAvailable } = data ?? {};

  const methods = useForm<FormValues>({});

  const createLabelPDF = useCreateLabelPDF();

  const handleSubmit = async (values: FormValues) => {
    const { format, packingList } = values;
    patch({ printFormat: format });

    const shouldPrintInChunks = ids.length > PRINT_CHUNK_SIZE;

    if (shouldPrintInChunks) {
      setResults([]);

      for (const sIds of chunk(ids, PRINT_CHUNK_SIZE)) {
        const result = await createLabelPDF.mutateAsync({ shipmentIds: sIds, format, packingList });
        setResults((prev) => [...prev, result.url]);
      }
    } else {
      createLabelPDF.mutate(
        { shipmentIds: ids, format, packingList },
        {
          onSuccess: (result: CreateLabelPDFResponse) => {
            window.open(result.url, '_blank');
            onClose();
            onSuccess?.();
          },
        },
      );
    }
  };

  useMemo(() => {
    if (!isOpen) return;
    if (shipmentIds) setIds(shipmentIds);
  }, [shipmentIds, isOpen]);

  useEffect(() => {
    if (!printFormat && !labelOptions) return;

    const preferred = labelOptions?.find((option: any) => option.value === printFormat);
    if (!preferred) return;

    methods.setValue('format', preferred.value);
  }, [printFormat, labelOptions]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <FormProvider {...methods}>
        <form onSubmit={stopPropagate(methods.handleSubmit(handleSubmit))}>
          <ModalContent>
            <ModalHeader>{title ?? 'Print label'}</ModalHeader>
            <ModalBody>
              <Stack spacing="4">
                {ids.length >= 50 && (
                  <Alert status="warning">
                    <AlertIcon />
                    <Stack spacing={1}>
                      <AlertDescription>
                        You are about to print <strong>{ids.length} labels</strong>. This operation may take a
                        few minutes to complete.
                      </AlertDescription>
                    </Stack>
                  </Alert>
                )}

                <FormControl isDisabled={isLoading || createLabelPDF.isLoading}>
                  <FormLabel>Print format</FormLabel>
                  <Select {...methods.register('format')}>
                    {labelOptions?.map((option: any) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>

                <FormControl
                  hidden={!isPackingListAvailable}
                  isDisabled={isLoading || createLabelPDF.isLoading}
                >
                  <Controller
                    name="packingList"
                    control={methods.control}
                    render={({ field }) => (
                      <HStack spacing="2.5" alignItems="start">
                        <Checkbox pt="1" isChecked={field.value} onChange={field.onChange} />
                        <Stack spacing="0">
                          <FormLabel m="0">Packing slip</FormLabel>
                          <Text color="gray.500">
                            Attach a packing slip {shipmentIds.length > 1 ? 'to every' : 'to the'} label.
                          </Text>
                        </Stack>
                      </HStack>
                    )}
                  />
                </FormControl>
              </Stack>

              <Stack mt="4">
                {results?.map((url, i) => {
                  return (
                    <chakra.a
                      role="group"
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      key={i}
                      border="1px"
                      rounded="md"
                      borderColor="zinc.300"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      px="4"
                      py="4"
                      _hover={{ borderColor: 'zinc.400', bg: 'zinc.50' }}
                    >
                      <Stack spacing={0}>
                        <Text fontWeight="semibold">
                          {i + 1}/{chunk(ids, PRINT_CHUNK_SIZE).length + 1} - Labels
                        </Text>
                        <Text color="zinc.500">Click to download</Text>
                      </Stack>

                      <Icon as={LuDownload} color="brand.500" boxSize="6" />
                    </chakra.a>
                  );
                })}
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button type="button" variant="outline" onClick={onClose} isDisabled={createLabelPDF.isLoading}>
                Cancel
              </Button>
              <Button
                ml="2"
                type="submit"
                colorScheme="brand"
                isLoading={createLabelPDF.isLoading}
                isDisabled={results.length > 0}
              >
                {ids.length === 1 ? 'Print' : `Print ${ids.length} labels`}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
}
