import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { PrintLabelModalProps } from './types';
import { useCreateLabelZPL } from 'api/shipments/label-zpl';

export function PrintZPLLabels(props: PrintLabelModalProps) {
  const { isOpen, onClose, shipmentIds = [], onSuccess } = props;

  const [packingSlip, setPackingSlip] = useState(true);

  const createLabelZPL = useCreateLabelZPL();

  const handlePrint = () => {
    createLabelZPL.mutate(
      { shipmentIds, packingList: packingSlip },
      {
        onSuccess: (data) => {
          window.open(data.url, '_blank');
          onClose();
          onSuccess?.();
        },
      },
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Print ZPL Labels</ModalHeader>
        <ModalBody>
          <Checkbox
            alignItems="start"
            defaultChecked={packingSlip}
            onChange={(e) => setPackingSlip(e.target.checked)}
          >
            <Text mt={-1}>Include packing slip</Text>
            <Text color="muted">If available, a packing slip will be printed with each label</Text>
          </Checkbox>
        </ModalBody>
        <ModalFooter>
          <Button isLoading={createLabelZPL.isLoading} colorScheme="brand" onClick={handlePrint}>
            Print {shipmentIds.length} labels
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
