import { Box, Button, ButtonGroup, Heading } from '@chakra-ui/react';
import { getShipments as getShipmentsAxios } from 'api/shipments';
import ContainerLayout from 'components/layouts/container-layout';
import { PageBody, PageHeader } from 'components/Page';
import useDebounce from 'hooks/use-debounce';
import { useDisclosure } from 'hooks/use-disclosure';
import { useMemo, useRef, useState } from 'react';
import { FiPlus, FiPrinter } from 'react-icons/fi';
import { PrintLabelModal } from '../../../components/print-label-modal';
import { columns } from './helpers';
import { AgGridReact } from 'ag-grid-react';
import { CustomAgGrid, getSelectedRows, getServerSideDatasource } from 'components/ag-grid';
import { useNavigate } from 'router';

export default function Shipments() {
  const gridRef = useRef<AgGridReact>(null);
  const tableId = 'shipments-list';

  const [searchQuery, setSearchQuery] = useState<string>('');

  const debouncedSearchQuery = useDebounce(searchQuery, 600);

  const printLabelModal = useDisclosure();
  const navigate = useNavigate();

  const onRefundSuccessful = () => {
    gridRef?.current?.api?.refreshServerSide();
    gridRef?.current?.api?.deselectAll();
  };

  const shipmentColumns = useMemo(() => columns({ onRefundSuccessful }), []);

  const dataSource = useMemo(
    () =>
      getServerSideDatasource(getShipmentsAxios, {
        q: debouncedSearchQuery || '',
      }),
    [debouncedSearchQuery],
  );

  const onSelectionChanged = () => {
    const selectedRows = getSelectedRows(gridRef);

    if (selectedRows.length > 0) {
      (document.getElementById('printLabelButton') as any).hidden = false;
      return;
    }

    (document.getElementById('printLabelButton') as any).hidden = true;
  };

  return (
    <ContainerLayout>
      <>
        <PageHeader flexDir={{ base: 'column', md: 'row' }} justifyContent="space-between">
          <Heading as="h1">Shipments</Heading>
          <ButtonGroup>
            <>
              <Button
                id="printLabelButton"
                type="button"
                variant="outline"
                hidden
                leftIcon={<FiPrinter />}
                onClick={printLabelModal.onOpen}
              >
                Print Label
              </Button>
            </>
            <Button colorScheme="brand" type="button" leftIcon={<FiPlus />} onClick={() => navigate('/ship')}>
              Add Shipment
            </Button>
          </ButtonGroup>
        </PageHeader>
        <PageBody>
          <Box>
            <CustomAgGrid
              viewEntity="shipments"
              setSearchQuery={setSearchQuery}
              height="calc(100vh - 290px)"
              onSelectionChanged={onSelectionChanged}
              tableId={tableId}
              ref={gridRef}
              columnDefs={shipmentColumns}
              dataSource={dataSource}
              hideCsvExport={false}
            />
          </Box>
        </PageBody>
        <PrintLabelModal
          zpl={getSelectedRows(gridRef)?.every((s) => s.isZplLabel) || false}
          isOpen={printLabelModal.isOpen}
          onClose={printLabelModal.onClose}
          shipmentIds={getSelectedRows(gridRef)?.map(({ id }) => Number(id))}
        />
      </>
    </ContainerLayout>
  );
}
