import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  Tooltip,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useDeleteMutation, useGetQuery, usePostMutation, usePutMutation } from 'api/client';
import { GetProductsResponse } from 'api/products/get';
import { Select as ReactSelect } from 'chakra-react-select';
import { EmptyState } from 'components/EmptyState';
import { useNotification } from 'contexts/notification.context';
import startCase from 'lodash/startCase';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { LuFileText, LuPlusCircle, LuSearch, LuTrash2 } from 'react-icons/lu';
import { chakraReactSelectStyles } from 'theme';
import { centsToDollars } from 'utils/currency';
import { z } from 'zod';
import { InventoryLogsDrawer } from '../inventory-logs-drawer';
import { StorePlatform } from 'types/enums';
import { StoreLogo } from 'components/store-logo';

export type Props = {
  product: ArrayElement<GetProductsResponse['results']>;
} & Omit<DrawerProps, 'children'>;

const schema = z.object({
  inventories: z.array(
    z.object({
      id: z.number().nullable(),
      locationId: z.number().min(1),
      availableQuantity: z.coerce.number(),
    }),
  ),
});

type FormValues = z.infer<typeof schema>;

export function EditInventoryDrawer(props: Props) {
  const { product, isOpen, onClose } = props;

  const logsDrawer = useDisclosure();

  const [selectedInventoryId, setSelectedInventoryId] = useState<number | null>(null);

  const updateInventory = usePutMutation('/api/v3/inventory/{id}');
  const createInventory = usePostMutation('/api/v3/inventory');
  const removeInventory2 = useDeleteMutation('/api/v3/inventory/{id}');

  const stores = useGetQuery('/api/v3/stores', {});
  const locations = useGetQuery('/api/v3/locations', {});
  const inventories = useGetQuery('/api/v3/inventory', {
    rq: { enabled: !!product?.id },
    params: { query: { productId: product!.id } },
  });

  const notify = useNotification();

  const form = useForm<FormValues>({
    resolver: zodResolver(schema),
    values: {
      inventories:
        inventories.data?.map((i) => ({
          id: i.id,
          locationId: i.location.id,
          availableQuantity: i.availableQuantity,
        })) || [],
    },
  });

  const { fields, remove } = useFieldArray({ control: form.control, name: 'inventories' });

  const handleInventoryCreate = () => {
    // Pick a location that hasn't been selected yet
    const location = locations?.data?.find((l) => !fields.some((f) => f.locationId === l.id));
    if (!location) return;

    const availableQuantity = 0;
    const productId = product!.id;
    const locationId = location.id;

    const onSuccess = () => inventories.refetch();

    createInventory.mutate({ body: { availableQuantity, locationId, productId } }, { onSuccess });
  };

  const handleInventoryRemove = (index: number) => {
    const inventory = inventories.data?.[index];

    if (!inventory?.id) remove(index);
    else {
      const onSuccess = () => inventories.refetch();
      removeInventory2.mutate({ params: { path: { id: String(inventory.id) } } }, { onSuccess });
    }
  };

  const handleDisableInventory = (index: number) => {
    if (inventories.data?.[index].availableQuantity === 0) return false;
    return stores.data && inventories?.data?.[index].location.storeId
      ? stores?.data.find(
          (store) =>
            store.id === inventories?.data?.[index].location.storeId &&
            store.platform === inventories?.data?.[index].location.platform,
        )?.inventoryManagement !== 'vesyl'
      : false;
  };

  const handleDisableLocation = (locationId: number) => {
    return stores.data && locations?.data?.find((l) => l.id === locationId)?.storeId
      ? stores?.data.find(
          (store) =>
            store.id === locations?.data?.find((l) => l.id === locationId)?.storeId &&
            store.platform === locations?.data?.find((l) => l.id === locationId)?.platform,
        )?.inventoryManagement !== 'vesyl'
      : false;
  };

  const handleSubmit = form.handleSubmit(async (values) => {
    if (!product?.id) return;

    const result = await Promise.allSettled(
      values.inventories.map((i) =>
        updateInventory.mutateAsync({
          params: { path: { id: String(i.id) } },
          body: { availableQuantity: i.availableQuantity, locationId: i.locationId },
        }),
      ),
    );

    await inventories.refetch();

    if (result.every((r) => r.status === 'fulfilled')) {
      notify.success('Inventory updated successfully');
    }
  });

  const selectedLocations = form
    .watch('inventories')
    ?.filter((i) => i.locationId !== -1)
    ?.map((i) => i.locationId);

  useEffect(() => {
    if (!logsDrawer.isOpen) setSelectedInventoryId(null);
  }, [logsDrawer.isOpen]);

  if (!product) return null;

  return (
    <>
      <Drawer isOpen={isOpen} onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          {logsDrawer.isOpen && <DrawerOverlay />}

          <DrawerCloseButton mt={2} />

          <DrawerHeader>
            <div>{product.name}</div>
            <Text size="xs" fontWeight="normal" color="muted">
              Manage inventory
            </Text>
          </DrawerHeader>

          <Divider />

          <DrawerBody>
            <Stack mb={8} mt={4} bg="zinc.50" p={4} border="1px" rounded="md" borderColor="zinc.200">
              <HStack justifyContent="space-between">
                <Text fontWeight="medium">SKU</Text>
                <Text color="muted">{product?.sku}</Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text fontWeight="medium">Platforms</Text>
                <Text color="muted">{product?.platforms?.map((p) => startCase(p)).join(', ')}</Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text fontWeight="medium">Price</Text>
                <Text color="muted">${centsToDollars(product?.priceCents || 0)}</Text>
              </HStack>

              <HStack justifyContent="space-between">
                <Text fontWeight="medium">Total Quantity</Text>
                <Text color="muted">
                  {inventories.data
                    ?.map((inventory) => inventory.availableQuantity)
                    .reduce((a, b) => a + b, 0)}
                </Text>
              </HStack>
            </Stack>

            <chakra.form
              gap={8}
              display="flex"
              id="inventory-form"
              flexDirection="column"
              onSubmit={handleSubmit}
            >
              {fields.map((field, index) => (
                <Stack key={field.id}>
                  <HStack gap={1}>
                    <Text fontSize="md">Inventory #{index + 1}</Text>
                    <Button
                      ml="auto"
                      title="View Logs"
                      variant="outline"
                      size="xs"
                      onClick={() => {
                        logsDrawer.onOpen();
                        setSelectedInventoryId(form.getValues().inventories[index].id);
                      }}
                    >
                      <LuFileText />
                    </Button>
                    <Button
                      size="xs"
                      color="red.500"
                      variant="outline"
                      borderColor="red.500"
                      isDisabled={handleDisableInventory(index)}
                      onClick={() => handleInventoryRemove(index)}
                    >
                      <LuTrash2 />
                    </Button>
                  </HStack>
                  <HStack>
                    <Stack maxW="10">
                      <FormLabel mt={2}>Lot</FormLabel>
                      <Text h="10" mt="auto" fontWeight={'bold'} fontSize={'lg'}>
                        {inventories?.data?.[index].lotNumber || '-'}
                      </Text>
                    </Stack>

                    <Controller
                      control={form.control}
                      name={`inventories.${index}.locationId`}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => {
                        const options =
                          locations?.data
                            ?.filter((l) => !selectedLocations?.includes(l.id) || l.id === value)
                            // Exclude locations that belong to platforms not listed in the product's platforms array
                            ?.filter((l) =>
                              l?.platform ? product.platforms?.includes(l?.platform as StorePlatform) : true,
                            )
                            ?.map?.((l) => ({
                              label: l.platform ? (
                                <Flex gap={2}>
                                  {/* indicate from which platform the location is */}
                                  <StoreLogo platform={l.platform as StorePlatform} size={20} />
                                  <Text>
                                    {l.name + ' - ' + l.originAddress?.nickname || l.originAddress?.line1}
                                  </Text>
                                </Flex>
                              ) : (
                                `${l.name} - ${l.originAddress?.nickname || l.originAddress?.line1}`
                              ),
                              value: l.id,
                            })) || [];

                        return (
                          <FormControl isInvalid={!!form.formState.errors?.inventories?.[index]?.locationId}>
                            <FormLabel>Location</FormLabel>
                            <ReactSelect
                              ref={ref}
                              name={name}
                              onBlur={onBlur}
                              useBasicStyles
                              isSearchable
                              options={options}
                              placeholder="Select..."
                              chakraStyles={chakraReactSelectStyles}
                              onChange={(val: any) => onChange(val.value)}
                              value={options.find((c) => c.value === value) || ''}
                            />
                          </FormControl>
                        );
                      }}
                    />

                    <FormControl isInvalid={!!form.formState.errors?.inventories?.[index]?.availableQuantity}>
                      <FormLabel>Available Quantity</FormLabel>
                      <Tooltip
                        placement="top"
                        label={
                          handleDisableInventory(index)
                            ? `Inventory for this location is managed by ${inventories.data?.[index].location.platform}`
                            : undefined
                        }
                      >
                        <Input
                          {...form.register(`inventories.${index}.availableQuantity`)}
                          disabled={
                            handleDisableInventory(index) ||
                            handleDisableLocation(form.getValues().inventories[index].locationId)
                          }
                        />
                      </Tooltip>
                    </FormControl>
                  </HStack>
                </Stack>
              ))}
            </chakra.form>

            <Flex justifyContent="end" my={4} hidden={fields.length === 0}>
              <Button
                gap={2}
                variant="outline"
                size="sm"
                onClick={handleInventoryCreate}
                hidden={selectedLocations?.length === locations?.data?.length}
              >
                <LuPlusCircle />
                Add
              </Button>
            </Flex>

            <EmptyState
              hidden={fields.length > 0 && !inventories.isLoading}
              header="No Inventory"
              icon={LuSearch}
              border="1px"
              borderColor="zinc.200"
              rounded="md"
              py={24}
              secondaryAction={{
                variant: 'outline',
                children: 'Add',
                size: 'sm',
                leftIcon: <LuPlusCircle />,
                onClick: handleInventoryCreate,
              }}
            >
              <Text maxW="xs" fontSize="sm" color="muted">
                Get started by adding inventory locations and quantities for this product
              </Text>
            </EmptyState>
          </DrawerBody>

          <DrawerFooter>
            <Button variant="ghost">Cancel</Button>
            <Button type="submit" form="inventory-form" colorScheme="brand" ml="2">
              Confirm
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      {selectedInventoryId && (
        <InventoryLogsDrawer {...logsDrawer} inventoryId={selectedInventoryId} productId={product.id} />
      )}
    </>
  );
}
