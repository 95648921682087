import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FC, PropsWithChildren, useState } from 'react';
import { FaAmazon } from 'react-icons/fa';
import { GiRabbitHead } from 'react-icons/gi';
import { z } from 'zod';
import { TrackstarConnectButton } from '@trackstar/react-trackstar-link';
import { usePostMutation } from 'api/client';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;

  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '1.5', md: '8' }}
      justify="space-between"
    >
      {children}
    </Stack>
  );
};

const schema = z.object({
  name: z.string().nonempty(),
});

export type FormValues = z.infer<typeof schema>;

export const ConnectAmazonForm: FC<{}> = () => {
  const [hovered, setHovered] = useState(false);
  const getLinkToken = usePostMutation('/api/v3/stores/amazon/connect');
  const getStoreToken = usePostMutation('/api/v3/stores/amazon/callback');
  const handleUnfinishedStore = usePostMutation('/api/v3/stores/trackstar/unfinished');

  const integration = import.meta.env.VITE_ENV === 'production' ? 'fba' : 'sandbox';

  const integrationOptions = {
    sandbox: 'Trackstar Sandbox',
    fba: 'Amazon FBA',
  };

  const navigate = useNavigate();

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: '' },
    resolver: zodResolver(schema),
  });

  const { register, formState, control } = methods;

  const name = useWatch({ control, name: 'name' });

  return (
    <FormProvider {...methods}>
      <>
        <Stack>
          <Flex justify="center" alignItems="center" bg="zinc.200" rounded="full" boxSize={14}>
            <Icon as={integration === 'sandbox' ? GiRabbitHead : FaAmazon} boxSize={8} />
          </Flex>
          <Stack spacing={0}>
            <Text fontSize="lg" fontWeight="semibold">
              {integrationOptions[integration]}
            </Text>
            <Text color="muted">Connect Vesyl to your Amazon Store to import your orders.</Text>
          </Stack>
        </Stack>

        <Divider my={6} />

        <Stack>
          <Stack spacing="3.5" divider={<StackDivider />}>
            <FormControl isRequired isInvalid={!!formState.errors?.name}>
              <Section>
                <FormLabel variant="inline">Name</FormLabel>
                <Input {...register('name')} placeholder="e.g. My Amazon WMS" />
              </Section>
            </FormControl>
          </Stack>
          <Flex justify="end" pt="6">
            {name ? (
              <Flex onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)}>
                <TrackstarConnectButton
                  getLinkToken={async (): Promise<string> => {
                    const response = await getLinkToken.mutateAsync({
                      body: { name: methods.getValues('name') },
                    });
                    return response?.linkToken || '';
                  }}
                  integrationAllowList={[integration]}
                  buttonId={integration}
                  onClose={() =>
                    handleUnfinishedStore.mutate({
                      body: {
                        name: methods.getValues('name') || '',
                        platform: 'amazon',
                      },
                    })
                  }
                  onSuccess={async (authCode) =>
                    getStoreToken.mutate(
                      {
                        body: {
                          authCode,
                          name: methods.getValues('name'),
                        },
                      },
                      {
                        onSuccess: () => {
                          navigate('/stores?success=true');
                        },
                        onSettled() {
                          methods.setValue('name', '');
                        },
                      },
                    )
                  }
                  // onClose={() => navigate('/stores?success=true')}
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    backgroundColor: hovered ? '#6B9CB7' : '#91B5C9',
                    color: '#fff',
                    padding: '8px 15px',
                    borderRadius: '9999px',
                    minWidth: '2.5rem',
                    fontWeight: '500',
                    fontSize: '0.875rem',
                    height: '2.25rem',
                    cursor: 'pointer',
                    border: 'none',
                    transition: 'background-color 0.2s ease, color 0.2s ease',
                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  Connect
                </TrackstarConnectButton>
              </Flex>
            ) : (
              <Button type="submit" colorScheme="brand" isDisabled>
                Connect
              </Button>
            )}
          </Flex>
        </Stack>
      </>
    </FormProvider>
  );
};
