import { useCallback, useEffect, useState } from 'react';

/**
 * Hook for scanning barcodes.
 *
 * @param callback - The callback to call when a barcode is scanned.
 * @returns The barcode that was scanned.
 */
export const useBarcodeScan = (callback: (barcode: string) => void): string => {
  const [barcode, setBarcode] = useState<string>('');
  const [lastScanTime, setLastScanTime] = useState<number>(0);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent): void => {
      const currentTime = new Date().getTime();

      // Clear the barcode if the last scan was more than 100ms ago
      if (currentTime - lastScanTime > 100) setBarcode('');
      setLastScanTime(currentTime);

      let key = event.key;

      // Ignore the scan if the key is a modifier key
      if (event.isComposing || ['Shift', 'Control', 'Meta', 'Alt'].includes(key)) return;

      // If the key is Enter or Tab, call the callback
      if (key === 'Enter' || key === 'Tab') {
        if (!barcode) return;
        callback(barcode);
      } else setBarcode((prevBarcode) => prevBarcode + key);
    },
    [barcode, lastScanTime, callback],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return barcode;
};
