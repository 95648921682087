import { CardBody, Center, Flex, Heading, Spinner, Stack, Text, Card } from '@chakra-ui/react';
import {
  Ebay2Store,
  EbayStore,
  EtsyStore,
  ShipStationStore,
  ShopifyStore,
  useGetStore,
  WooCommerceStore,
} from 'api/stores';
import { EmptyState } from 'components/EmptyState';
import { PageBody, PageHeader } from 'components/Page';
import { useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { StorePlatform } from 'types/enums';
import { EditEbay } from '../_components/edit-ebay-form';
import { EditEtsy } from '../_components/edit-etsy-form';
import { EditShopify } from '../_components/edit-shopify-form';
import { EditWooCommerceForm } from '../_components/edit-woocommerce-form';
import { EditEbay2 } from '../_components/edit-ebay2-form';
import { EditShipStation } from '../_components/edit-shipstation-form';
import { EditExtensiv } from '../_components/edit-extensiv-form';
import { useNavigate, useParams } from 'router';
import { EditAmazon } from '../_components/edit-amazon-form';
import { EditWalmart } from '../_components/edit-walmart-form';

export default function () {
  const params = useParams('/stores/:platform/:id');
  const getStore = useGetStore();
  const navigate = useNavigate();

  const platform = params.platform as StorePlatform;
  const storeId = params.id as string;

  useEffect(() => {
    if (!platform || !storeId) return;

    getStore.mutate({ platform, storeId });
  }, [platform, storeId]);

  const isLoadingMarkup = getStore.isLoading && (
    <Flex minH="500px" alignItems="center" justify="center">
      <Stack alignItems="center">
        <Spinner />
        <Text color="muted">Loading...</Text>
      </Stack>
    </Flex>
  );

  const notFoundMarkup = !getStore.data && (
    <Center w="full" minH={400}>
      <EmptyState
        header="Store Not Found"
        icon={FiSearch}
        primaryAction={{
          children: 'Take me back',
          onClick: () => navigate('/stores'),
        }}
      >
        <p>We could not find the store you are looking for.</p>
      </EmptyState>
    </Center>
  );

  return (
    <>
      <PageHeader>
        <Heading as="h1">{getStore.data?.name || ''}</Heading>
      </PageHeader>
      <PageBody>
        <Card>
          <CardBody>
            {platform === StorePlatform.Ebay && getStore.data ? (
              <EditEbay store={getStore.data as EbayStore} />
            ) : null}

            {platform === StorePlatform.Shopify && getStore.data ? (
              <EditShopify store={getStore.data as ShopifyStore} />
            ) : null}

            {platform === StorePlatform.Etsy && getStore.data ? (
              <EditEtsy store={getStore.data as EtsyStore} />
            ) : null}

            {platform === StorePlatform.WooCommerce && getStore.data ? (
              <EditWooCommerceForm store={getStore.data as WooCommerceStore} />
            ) : null}

            {platform === StorePlatform.Ebay2 && getStore.data ? (
              <EditEbay2 store={getStore.data as Ebay2Store} />
            ) : null}

            {platform === StorePlatform.ShipStation && getStore.data ? (
              <EditShipStation store={getStore.data as ShipStationStore} />
            ) : null}

            {platform === StorePlatform.Extensiv && getStore.data ? (
              <EditExtensiv store={getStore.data} />
            ) : null}

            {platform === StorePlatform.Amazon && getStore.data ? <EditAmazon store={getStore.data} /> : null}

            {platform === StorePlatform.Walmart && getStore.data ? (
              <EditWalmart store={getStore.data} />
            ) : null}

            {isLoadingMarkup || notFoundMarkup}
          </CardBody>
        </Card>
      </PageBody>
    </>
  );
}
