import { ExtensivStore } from 'api/stores';
import {
  Button,
  chakra,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { useNotification } from 'contexts/notification.context';
import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { usePutMutation } from 'api/client';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '1.5', md: '8' }}
      justify="space-between"
    >
      {children}
    </Stack>
  );
};

const schema = z.object({
  name: z.string().nonempty(),
});

export type FormValues = z.infer<typeof schema>;

interface Props {
  store: ExtensivStore;
}

export const EditAmazon = ({ store }: Props) => {
  const updateStore = usePutMutation(`/api/v3/stores/amazon/{id}`);

  const { notifySuccess } = useNotification();
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: { name: store.name },
    resolver: zodResolver(schema),
  });

  const { register, formState } = methods;

  const onSubmit = async (values: FormValues) => {
    updateStore.mutate(
      {
        params: {
          path: {
            id: String(store.id),
          },
        },
        body: {
          name: values.name,
        },
      },
      {
        onSuccess: () => {
          notifySuccess('Your store has been successfully updated');
          navigate('/stores');
        },
      },
    );
  };

  return (
    <>
      <Stack>
        <Stack spacing={0}>
          <Text fontSize="lg" fontWeight="semibold">
            Update store
          </Text>
          <Text color="muted">Update your Amazon store information.</Text>
        </Stack>
      </Stack>

      <Divider my={6} />

      <chakra.form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack spacing="3.5" divider={<StackDivider />}>
          <FormControl isRequired isInvalid={!!formState.errors?.name}>
            <Section>
              <FormLabel variant="inline">Name</FormLabel>
              <Input {...register('name')} placeholder="e.g. My Etsy Store" />
            </Section>
          </FormControl>
        </Stack>
        <Flex justify="end" pt="6">
          <Button type="submit" colorScheme="brand" isLoading={updateStore.isLoading}>
            Update
          </Button>
        </Flex>
      </chakra.form>
    </>
  );
};
