import { queryClient } from 'config/query-client';
import createClient, { FetchOptions, Middleware } from 'openapi-fetch';
import type { HttpMethod, PathsWithMethod, SuccessResponseJSON } from 'openapi-typescript-helpers';
import {
  UseMutationOptions as RQUseMutationOptions,
  UseQueryOptions as RQUseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { useUserStore } from 'store';
import type { paths } from './types.d';

const baseUrl = import.meta.env.VITE_BASE_URL.replace(/\/api$/, '');

export const client = createClient<paths>({ baseUrl });

const authMiddleware: Middleware = {
  async onRequest(req, _options) {
    const state = useUserStore.getState();
    if (!state.authToken) return req;

    req.headers.set('Authorization', `Bearer ${state.authToken}`);

    if (req.method === 'DELETE' && !req.body) req.headers.delete('Content-Type');

    return req;
  },
};

client.use(authMiddleware);

type UseQueryOptions = Pick<RQUseQueryOptions, 'enabled'> & { refetchInterval?: number };
type UseMutationOptions = Pick<RQUseMutationOptions, 'retry'>;

type Paths<M extends HttpMethod> = PathsWithMethod<paths, M>;
type Params<M extends HttpMethod, P extends Paths<M>> = M extends keyof paths[P]
  ? FetchOptions<paths[P][M]>
  : never;

export function usePostMutation<P extends Paths<'post'>>(path: P, opts?: UseMutationOptions) {
  return useMutation({
    mutationFn: async (params: Params<'post', P>) => {
      const { data, error } = await client.POST(path, params);
      if (error) throw new Error(error.message);
      return data;
    },
    ...opts,
  });
}

export function useGetQuery<P extends Paths<'get'>>(
  path: P,
  params: Params<'get', P> & { rq?: UseQueryOptions },
) {
  return useQuery({
    queryKey: [path, params],
    queryFn: async () => {
      const { data, error } = await client.GET(path, params);
      if (error) throw new Error(error.message);
      return data;
    },
    ...params?.rq,
  });
}

export function usePutMutation<P extends Paths<'put'>>(path: P, opts?: UseMutationOptions) {
  return useMutation({
    mutationFn: async (params: Params<'put', P>) => {
      const { error, data } = await client.PUT(path, params);
      if (error) throw new Error(error.message);
      return data;
    },
    ...opts,
  });
}

export function useDeleteMutation<P extends Paths<'delete'>>(path: P, opts?: UseMutationOptions) {
  return useMutation({
    mutationFn: async (params: Params<'delete', P>) => {
      const { data, error } = await client.DELETE(path, params);
      if (error) throw new Error(error.message);
      return data;
    },
    ...opts,
  });
}

export function invalidateQueries<P extends Paths<'get'>>(path: P | P[]) {
  return queryClient.invalidateQueries(path);
}

export type ApiInput<M extends HttpMethod, P extends Paths<M>> = Params<M, P>;

export type ApiResult<M extends HttpMethod, P extends Paths<M>> = M extends keyof paths[P]
  ? SuccessResponseJSON<paths[P][M]>
  : never;
