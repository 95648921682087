import { paths } from 'api/types';
import axios from 'config/axios';
import { useQuery, UseQueryOptions } from 'react-query';

export type GetOrdersQuery = paths['/api/v3/orders']['get']['parameters']['query'];
export type GetOrdersResponse =
  paths['/api/v3/orders']['get']['responses']['200']['content']['application/json'];

export const getOrders = async (input?: GetOrdersQuery) => {
  if (!input) return null;

  const { ...rest } = input;
  const result = await axios.get(`/v3/orders`, { params: { ...rest } });
  return result?.data;
};

export const GET_ORDERS_QUERY = 'getOrders';

export const useGetOrders = (input?: GetOrdersQuery, opts?: UseQueryOptions<any>) =>
  useQuery<GetOrdersResponse>([GET_ORDERS_QUERY, input], () => getOrders(input), opts ?? {});
