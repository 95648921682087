import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';
import { invalidateQueries, usePutMutation } from 'api/client';
import { useNotification } from 'contexts/notification.context';
import { useForm } from 'react-hook-form';

interface EditLocationModalProps extends Omit<ModalProps, 'children'> {
  location: { id: number | null; name: string | null };
}

export function EditLocationModal(props: EditLocationModalProps) {
  const { location, onClose, ...rest } = props;
  const form = useForm({ defaultValues: { name: location.name } });

  const notify = useNotification();

  const editLocation = usePutMutation('/api/v3/locations/{id}');

  const onSubmit = form.handleSubmit((values) => {
    if (values.name === null || location.id === null || values.name === null) return;
    const { name } = values;

    editLocation.mutate(
      {
        params: { path: { id: location.id } },
        body: { name },
      },
      {
        onSuccess: () => {
          notify.success('Location updated successfully');
          invalidateQueries('/api/v3/locations');
          onClose();
        },
      },
    );
  });
  return (
    <Modal onClose={onClose} {...rest}>
      <ModalOverlay />
      <ModalContent>
        <form onSubmit={onSubmit}>
          <ModalHeader>Edit location</ModalHeader>
          <ModalBody>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input {...form.register('name')} />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button mr={3} variant={'ghost'} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="brand" type="submit">
              Confirm
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
