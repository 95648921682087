import {
  Input,
  Select,
  Button,
  Flex,
  chakra,
  Divider,
  FormControl,
  FormLabel,
  Stack,
  StackDivider,
  Text,
  Spinner,
} from '@chakra-ui/react';
import { ShopifyStore } from 'api/stores';
import { useGetShopifyStoreLocations } from 'api/stores/shopify/get-locations';
import useUpdateShopifyStore from 'api/stores/shopify/update';
import { useNotification } from 'contexts/notification.context';
import { PropsWithChildren } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { z } from 'zod';

const Section = (props: PropsWithChildren<{}>) => {
  const { children } = props;
  return (
    <Stack
      direction={{ base: 'column', md: 'row' }}
      spacing={{ base: '1.5', md: '8' }}
      justify="space-between"
    >
      {children}
    </Stack>
  );
};

const schema = z.object({
  name: z.string().nonempty(),
  fulfillmentLocationId: z.string().nonempty(),
  inventoryManagement: z.string(),
});

export type FormValues = z.infer<typeof schema>;

interface Props {
  store: ShopifyStore;
}

export const EditShopify = ({ store }: Props) => {
  const updateStore = useUpdateShopifyStore();
  const locations = useGetShopifyStoreLocations({ id: store.id });

  const { notifySuccess } = useNotification();
  const navigate = useNavigate();
  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      name: store.name,
      fulfillmentLocationId: store.fulfillmentLocationId ?? undefined,
      inventoryManagement: store.inventoryManagement ?? '',
    },
  });

  const { handleSubmit, register, formState } = methods;

  const onSubmit = async (values: FormValues) => {
    updateStore.mutate(
      {
        storeId: store.id,
        name: values.name,
        fulfillmentLocationId: values.fulfillmentLocationId,
        ...(values.inventoryManagement && { inventoryManagement: values.inventoryManagement }),
      },
      {
        onSuccess: () => {
          notifySuccess('Your store has been successfully updated');
          navigate('/stores');
        },
      },
    );
  };

  return (
    <>
      <Stack>
        <Stack spacing={0}>
          <Text fontSize="lg" fontWeight="semibold">
            Update store
          </Text>
          <Text color="muted">Update your Shopify store information.</Text>
        </Stack>
      </Stack>

      <Divider my={6} />

      <chakra.form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="3.5" divider={<StackDivider />}>
          <FormControl isRequired isInvalid={!!formState.errors?.name}>
            <Section>
              <FormLabel variant="inline">Name</FormLabel>
              <Input {...register('name')} placeholder="e.g. My Shopify Store" />
            </Section>
          </FormControl>
          <Section>
            <FormLabel variant="inline">URL</FormLabel>
            <Input placeholder={store.url} isDisabled />
          </Section>
          <Section>
            <FormLabel variant="inline">Fulfillment Location</FormLabel>
            {!locations.isLoading ? (
              <Select disabled={locations.isLoading} {...register('fulfillmentLocationId')}>
                {locations?.data?.map((location) => (
                  <option key={location.id} value={location.id}>
                    {location.name}
                  </option>
                ))}
              </Select>
            ) : (
              <Spinner />
            )}
          </Section>
          <Section>
            <FormLabel variant="inline">Inventory management</FormLabel>
            <Select {...register('inventoryManagement')}>
              <option value="shopify">Shopify</option>
              <option value="vesyl">Vesyl</option>
              <option value="">None</option>
            </Select>
          </Section>
        </Stack>
        <Flex justify="end" pt="6">
          <Button type="submit" colorScheme="brand" isLoading={updateStore.isLoading}>
            Update
          </Button>
        </Flex>
      </chakra.form>
    </>
  );
};
