import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { AddressPopover } from 'components/AddressPopover';
import { CarrierLogo } from 'components/CarrierLogo';
import { ProductsPopover } from 'components/new/products-popover';
import { StoreLogo } from 'components/store-logo';
import { useDisclosure } from 'hooks/use-disclosure';
import { capitalize, noop, pick, startCase, uniqBy } from 'lodash';
import { BiPrinter, BiQr } from 'react-icons/bi';
import { BsThreeDots } from 'react-icons/bs';
import { FaShippingFast } from 'react-icons/fa';
import { FiCheck, FiClipboard, FiRepeat } from 'react-icons/fi';
import { GrRedo } from 'react-icons/gr';
import { ImEye } from 'react-icons/im';
import { RiRefund2Line } from 'react-icons/ri';
import { useNavigate } from 'router';
import { ShipmentAddress } from 'types/shipment.d';
import { getCountryNames, getNameFromStatus, ouncesToPounds, statuses } from 'utils/misc';
import { PrintLabelModal } from '../../../components/print-label-modal';
import { RefundLabelModal } from './_components/refund-modal';
import { GetShipmentsResponse } from 'api/shipments/get';
import { ColDef, ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import useCreateQRcode from 'api/shipments/label-qrcode';
import useClipboard from 'react-use-clipboard';
import dayjs from 'dayjs';
import { Order } from 'types';
import { StorePlatform } from 'types/enums';

type Shipment = ArrayElement<GetShipmentsResponse['results']>;

export const parseAddress = (address?: ShipmentAddress) => {
  if (!address) return null;
  const { name, line1, line2, city, state, postal, country } = address;

  return `${name}\n${line1} ${line2 ?? ''}\n${city}, ${state} ${postal}\n${getCountryNames(country || '')}`;
};

export const renderAddress = (address?: ShipmentAddress) => {
  if (!address) return null;
  const { name, line1, line2, city, state, postal, country } = address;

  return (
    <VStack alignItems="start" spacing={0}>
      <Text>{name}</Text>
      <Text>{`${line1} ${line2 ?? ''}`}</Text>
      <Text>{`${city}, ${state} ${postal}`}</Text>
      <Text>{getCountryNames(country || '')}</Text>
    </VStack>
  );
};

export function getNameFromService(input: string): string | undefined {
  const service = input;

  switch (service) {
    case 'Express':
      return 'Priority Mail Express';
    case 'ParcelSelect':
      return 'Parcel Select';
    case 'Priority':
      return 'Priority Mail';
    case 'First':
      return 'First Class Mail';
    case 'MediaMail':
      return 'Media Mail';
    case 'FirstClassPackageInternationalService':
      return 'First Class Package International';
    case 'PriorityMailInternational':
      return 'Priority Mail International';
    case 'ExpressMailInternational':
      return 'Priority Mail Express International';
    case 'GroundAdvantage':
      return 'Ground Advantage';
    default:
      return startCase(service);
  }
}

export const columns = ({ onRefundSuccessful = noop }) =>
  [
    {
      colId: 'checkbox',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      resizable: false,
      width: 50,
      lockPosition: true,
    },
    {
      field: 'actions',
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.data.id;
        const refundModal = useDisclosure(false);
        const printLabelModal = useDisclosure();

        if (!params.data) return null;
        const { refundStatus, canRefund, id: shipmentId, isZplLabel } = params.data;

        const navigate = useNavigate();
        const getQRcode = useCreateQRcode();

        const handleQRcode = async (id: number) => {
          const onSuccess = (result: { url: string }) => {
            window.open(result.url, '_blank');
          };
          getQRcode.mutate({ shipmentId: id }, { onSuccess });
        };

        return (
          <Box w="full">
            <Menu>
              <MenuButton as={Button} size="xs" variant="ghost" isLoading={getQRcode.isLoading}>
                <BsThreeDots size="18px" />
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem onClick={() => navigate(`/shipments/:id`, { params: { id: String(value) } })}>
                    <ImEye />
                    <Text ml={2}>View Shipment</Text>
                  </MenuItem>
                  <MenuItem onClick={printLabelModal.onOpen}>
                    <BiPrinter />
                    <Text ml={2}>Print Label</Text>
                  </MenuItem>
                  <MenuItem disabled={getQRcode.isLoading} onClick={() => handleQRcode(shipmentId)}>
                    <BiQr />
                    <Text ml={2}>View QR code</Text>
                  </MenuItem>
                  <MenuItem disabled={!canRefund} onClick={refundModal.onOpen}>
                    {refundStatus === 'submitted' ? <FiCheck /> : <RiRefund2Line />}
                    <Text ml={2}>{refundStatus === 'submitted' ? 'Refund Submitted' : 'Refund Label'}</Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(`/ship`, {
                        state: { shipment: pick(params.data, ['to', 'options', 'parcel']) },
                      })
                    }
                  >
                    <GrRedo />
                    <Text ml={2}>Ship Again</Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      const shipment = { ...params.data };
                      shipment.to = { ...params.data.from, printCustom1: params.data.order?.reference };
                      shipment.from = { ...params.data.to };
                      shipment.options.isReturn = true;
                      navigate(`/ship`, { state: { shipment } });
                    }}
                  >
                    <FiRepeat />
                    <Text ml={2}>Create Return Label</Text>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
            <RefundLabelModal
              shipmentId={Number(value)}
              isOpen={refundModal.isOpen}
              onClose={refundModal.onClose}
              onSuccess={onRefundSuccessful}
            />
            <PrintLabelModal
              zpl={isZplLabel}
              isOpen={printLabelModal.isOpen}
              onClose={printLabelModal.onClose}
              shipmentIds={[params.data.id]}
            />
          </Box>
        );
      },
    },
    {
      headerName: 'Store',
      field: 'orderPlatform',
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.order?.platform;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;

        return (
          <Flex alignItems="center" justify="start" h="full">
            {data.order?.platform ? (
              <StoreLogo size={20} platform={data.order.platform} />
            ) : (
              <FaShippingFast fontSize={20} title="Quick Ship" />
            )}
          </Flex>
        );
      },
    },
    {
      headerName: 'Carrier',
      colId: 'carrier',
      sortable: true,
      field: 'purchasedRate.carrier',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'] },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.purchasedRate?.carrier;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;
        if (!data.purchasedRate?.carrier) return null;
        return (
          <Flex alignItems="center" h="full" w="full">
            <CarrierLogo carrier={data.purchasedRate.carrier} />
          </Flex>
        );
      },
    },
    {
      headerName: 'Products',
      field: 'products',
      cellRenderer: (params: ICellRendererParams) => {
        const order: Order = params.data?.order;
        if (!order) return null;
        const products = order?.lineItems?.map((v) => ({ ...v.product, quantity: v.quantity }));
        if (!products) return null;

        let title = '';
        const skus = uniqBy(products, 'sku');

        if (skus.length === 0) return `(${skus.length} items)`;
        if (skus.length === 1 && skus[0]?.name) title = skus[0].name;
        else title = `(${skus.length} items)`;

        return <ProductsPopover products={products} title={title} />;
      },
    },
    {
      headerName: 'Status',
      field: 'status',
      filter: 'agSetColumnFilter',
      filterParams: {
        values: statuses,
        valueFormatter: (params: ValueFormatterParams<Shipment, string>) => {
          if (!params.value) return null;
          return getNameFromStatus(params.value);
        },
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.tracker?.status;
      },
      valueFormatter: (params: ValueFormatterParams<Shipment, string>) => {
        if (!params.value) return null;
        return getNameFromStatus(params.value);
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;
        const { refundStatus } = data;
        const { status } = data.tracker || {};
        let tag = null;

        if (status === 'unknown' || !status) tag = <Tag colorScheme="yellow">Label Created</Tag>;
        if (['out_for_delivery', 'in_transit', 'pre_transit', 'out_for_delivery'].includes(status))
          tag = <Tag colorScheme="yellow">{startCase(status)}</Tag>;
        if (['delivered', 'available_for_pickup'].includes(status))
          tag = <Tag colorScheme="green">{startCase(status)}</Tag>;

        if (refundStatus === 'submitted') tag = <Tag colorScheme="blue">Refund Submitted</Tag>;
        if (refundStatus === 'refunded') tag = <Tag colorScheme="blue">Refunded</Tag>;

        if (!tag) return null;
        return (
          <Flex alignItems="center" justify="start">
            {tag}
          </Flex>
        );
      },
    },
    {
      headerName: 'Service',
      colId: 'service',
      field: 'purchasedRate.service',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'] },
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        if (!params.data?.purchasedRate?.service) return null;
        return getNameFromService(params.data.purchasedRate.service);
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;
        if (!data.purchasedRate?.service) return null;
        return (
          <Flex alignItems="center" h="full" w="fit-content">
            <Tag>{getNameFromService(data.purchasedRate.service)}</Tag>
          </Flex>
        );
      },
    },
    {
      field: 'cost',
      colId: 'price',
      sortable: true,
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
      },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.purchasedRate?.price;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;
        return <p>{data.purchasedRate?.price ? `$${data.purchasedRate.price.toFixed(2)}` : ''}</p>;
      },
    },
    {
      colId: 'weight',
      field: 'parcel.weight',
      headerName: 'Weight Lbs',
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
        numberParser: (params: any) => {
          if (!params) return null;
          return Number(params) * 16;
        },
      },
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        return ouncesToPounds(params.data?.parcel?.weight || 0);
      },
      valueFormatter: (params: ValueFormatterParams<Shipment, number>) => {
        if (!params.value) return null;
        return `${params.value.toFixed(2)} lbs`;
      },
    },
    {
      colId: 'weightOz',
      field: 'parcel.weight',
      headerName: 'Weight Oz',
      filter: 'agNumberColumnFilter',
      filterParams: {
        filterOptions: ['equals', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
      },
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data?.parcel?.weight || 0;
      },
      valueFormatter: (params: ValueFormatterParams<Shipment, number>) => {
        if (!params.value) return null;
        return `${params.value.toFixed(2)} oz`;
      },
    },
    {
      field: 'package',
      valueGetter: (params: ValueGetterParams) => {
        const { data } = params;
        if (!data.predefinedPackage)
          return `${data.parcel.length || 0} x ${data.parcel.width || 0} x ${data.parcel.height || 0} in`;

        return startCase(data.predefinedPackage);
      },
    },
    {
      field: 'to',
      wrapText: false,
      valueGetter: (params: ValueGetterParams) => {
        return parseAddress(params.data.to);
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;
        return <AddressPopover triggerButtonProps={{ maxW: '170px' }} address={data.to} />;
      },
    },
    {
      headerName: 'To - City',
      field: 'toCity',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'], maxNumConditions: 1 },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.to.city;
      },
    },
    {
      headerName: 'To - Name',
      field: 'toName',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'], maxNumConditions: 1 },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.to.name;
      },
    },
    {
      headerName: 'To - Line 1',
      field: 'toLine1',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'], maxNumConditions: 1 },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.to.line1;
      },
    },
    {
      headerName: 'To - Line 2',
      field: 'toLine2',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'], maxNumConditions: 1 },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.to.line2;
      },
    },
    {
      headerName: 'To - Country',
      field: 'toCountry',
      filter: 'agTextColumnFilter',
      filterParams: { filterOptions: ['contains'], maxNumConditions: 1 },
      valueGetter: (params: ValueGetterParams) => {
        return params.data.to.country;
      },
    },
    {
      field: 'uspsZone',
      headerName: 'Zone',
      sortable: true,
      valueGetter: (params: ValueGetterParams) => {
        return params.data.uspsZone;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;
        return (
          <Box display="flex" alignItems="center" h="full">
            <Tag>Zone {data.uspsZone}</Tag>
          </Box>
        );
      },
    },
    {
      headerName: 'Order',
      field: 'orderId',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.order?.platformId;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;

        if (!data) return null;
        if (!data.order?.platformId) return null;
        const [isCopied, setCopied] = useClipboard(
          data.order?.platform === StorePlatform.Shopify
            ? data.order?.reference
            : data.order?.platformId ?? '---',
          {
            successDuration: 1400,
          },
        );

        if (isCopied)
          return (
            <HStack color="green.600">
              <Text fontSize="sm">Copied</Text>
              <FiCheck />
            </HStack>
          );

        return (
          <HStack>
            <IconButton
              aria-label="Copy order number"
              icon={<Icon boxSize={3} as={FiClipboard} />}
              variant="outline"
              size="xs"
              onClick={setCopied}
            />
            <Text>
              {data.order?.platform === StorePlatform.Shopify
                ? data.order?.reference
                : data.order?.platformId}
            </Text>
          </HStack>
        );
      },
    },
    {
      field: 'batch',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.order?.batches;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { data } = params;
        if (!data) return null;
        const [batch] = params.value || [];
        const navigate = useNavigate();

        if (!batch) return null;
        return (
          <Button
            variant="link"
            onClick={() => navigate(`/orders/batches/:id`, { params: { id: String(batch.id) } })}
          >
            {batch?.displayName}
          </Button>
        );
      },
    },
    {
      field: 'tracking',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.tracker;
      },
      cellRenderer: (params: ICellRendererParams) => {
        const { value } = params;
        if (!value) return null;
        const [isCopied, setCopied] = useClipboard(value?.code ?? '---', {
          successDuration: 1400,
        });
        if (!value?.code) return null;
        if (isCopied)
          return (
            <HStack color="green.600">
              <Text fontSize="sm">Copied</Text>
              <FiCheck />
            </HStack>
          );
        return (
          <HStack gap={1}>
            <IconButton
              aria-label="Copy tracking number"
              icon={<Icon boxSize={3} as={FiClipboard} />}
              variant="outline"
              size="xs"
              onClick={setCopied}
            />
            <Link color="brand.900" target="_blank" rel="noopener noreferrer" href={value?.url ?? '#'}>
              <Text fontSize="xs">{value?.code || 'Track'}</Text>
            </Link>
          </HStack>
        );
      },
    },
    {
      field: 'actions',
      cellRenderer: (params: ICellRendererParams) => {
        const value = params.data.id;
        const refundModal = useDisclosure(false);
        const printLabelModal = useDisclosure();

        if (!params.data) return null;
        const { refundStatus, canRefund, id: shipmentId, isZplLabel } = params.data;

        const navigate = useNavigate();
        const getQRcode = useCreateQRcode();

        const handleQRcode = async (id: number) => {
          const onSuccess = (result: { url: string }) => {
            window.open(result.url, '_blank');
          };
          getQRcode.mutate({ shipmentId: id }, { onSuccess });
        };

        return (
          <Box w="full">
            <Menu>
              <MenuButton as={Button} size="xs" variant="ghost" isLoading={getQRcode.isLoading}>
                <BsThreeDots size="18px" />
              </MenuButton>
              <Portal>
                <MenuList>
                  <MenuItem onClick={() => navigate(`/shipments/:id`, { params: { id: `${value}` } })}>
                    <ImEye />
                    <Text ml={2}>View Shipment</Text>
                  </MenuItem>
                  <MenuItem onClick={printLabelModal.onOpen}>
                    <BiPrinter />
                    <Text ml={2}>Print Label</Text>
                  </MenuItem>
                  <MenuItem disabled={getQRcode.isLoading} onClick={() => handleQRcode(shipmentId)}>
                    <BiQr />
                    <Text ml={2}>View QR code</Text>
                  </MenuItem>
                  <MenuItem disabled={!canRefund} onClick={refundModal.onOpen}>
                    {refundStatus === 'submitted' ? <FiCheck /> : <RiRefund2Line />}
                    <Text ml={2}>{refundStatus === 'submitted' ? 'Refund Submitted' : 'Refund Label'}</Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      navigate(`/ship`, {
                        state: { shipment: pick(params.data, ['to', 'options', 'parcel']) },
                      })
                    }
                  >
                    <GrRedo />
                    <Text ml={2}>Ship Again</Text>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      const shipment = { ...params.data };
                      shipment.to = { ...params.data.from };
                      shipment.from = { ...params.data.to };
                      shipment.options.isReturn = true;
                      navigate(`/ship`, { state: { shipment } });
                    }}
                  >
                    <FiRepeat />
                    <Text ml={2}>Create Return Label</Text>
                  </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
            <RefundLabelModal
              shipmentId={Number(value)}
              isOpen={refundModal.isOpen}
              onClose={refundModal.onClose}
              onSuccess={onRefundSuccessful}
            />
            <PrintLabelModal
              zpl={isZplLabel}
              isOpen={printLabelModal.isOpen}
              onClose={printLabelModal.onClose}
              shipmentIds={[params.data.id]}
            />
          </Box>
        );
      },
    },
    {
      headerName: 'Created',
      field: 'createdAt',
      filter: 'agDateColumnFilter',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.createdAt;
      },
      valueFormatter: (params: ValueFormatterParams<Shipment, string>) => {
        if (!params.value) return null;
        return dayjs(params.value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'labelDate',
      valueGetter: (params: ValueGetterParams) => {
        return params.data.options?.labelDate;
      },
      valueFormatter: (params: ValueFormatterParams<Shipment, string>) => {
        if (!params.value) return null;
        return dayjs(params.value).format('MM/DD/YYYY');
      },
    },
    {
      field: 'labelRequestSource',
      headerName: 'Label Source',
      valueFormatter: (params: ValueFormatterParams<Shipment, string>) => {
        if (!params.value) return null;
        return capitalize(startCase(params.value));
      },
    },
    {
      field: 'printCustom',
    },
  ] as ColDef<Shipment>[];
