import { Box, Tab, TabList, TabProps, Tabs } from '@chakra-ui/react';
import { useGetQuery } from 'api/client';
import { PageHeader } from 'components/Page';
import { RequirePermission } from 'components/require-permission';
import { Link, Outlet, useLocation } from 'react-router-dom';

export default function () {
  const { pathname } = useLocation();

  const locations = useGetQuery('/api/v3/locations', {});
  if (!locations.data) return null;

  const [location] = locations.data;
  if (!location) return null;

  const tabs: Array<TabProps & { to?: string }> = [
    { children: 'Locations', as: Link, to: `/inventories/locations/${location.id}` },
    { children: 'Receiving', as: Link, to: `/inventories/receiving` },
    { children: 'Move Inventory', isDisabled: true },
    { children: 'Analytics', as: Link, to: `/inventories/analytics` },
    { children: 'Cycle Count', isDisabled: true },
  ];

  const selectedIndex = tabs.findIndex((tab) => tab.to?.includes(pathname));

  return (
    <RequirePermission noOutlet permission="viewProducts">
      <Box>
        <PageHeader>
          <h1>Inventory</h1>
        </PageHeader>
        <Tabs defaultIndex={selectedIndex} colorScheme="brand" isLazy>
          <TabList>
            {tabs.map((tab, index) => {
              return <Tab key={index} {...tab} />;
            })}
          </TabList>
        </Tabs>
        <Outlet />
      </Box>
    </RequirePermission>
  );
}
