import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Heading,
  HStack,
} from '@chakra-ui/react';
import { AgGridReact } from 'ag-grid-react';
import { useGetAutomations, useRunAutomations } from 'api/automation';
import { useGetQuery } from 'api/client';
import { useDeleteOrders } from 'api/orders/delete';
import { getOrders as getOrdersAxios } from 'api/orders/get';
import { useUpdateOrder } from 'api/orders/update';
import { useSyncStore } from 'api/stores';
import { useGetAuthUrl } from 'api/stores/shopify/get-auth-url';
import { CustomAgGrid, getSelectedRows, getServerSideDatasource } from 'components/ag-grid';
import { ConfirmationModal } from 'components/new/confirmation-modal';
import { PageBody, PageHeader } from 'components/Page';
import { useNotification } from 'contexts/notification.context';
import { useDisclosure } from 'hooks/use-disclosure';
import startCase from 'lodash/startCase';
import { columns } from 'pages/(app)/orders/_components/helpers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FiExternalLink } from 'react-icons/fi';
import { LuImport } from 'react-icons/lu';
import { Order } from 'types';
import { OrderStatus } from 'types/enums';
import { diff } from 'utils/dates';
import { BulkEditDrawer } from './_components/bulk-edit-drawer';
import { BuyBatchModal } from './_components/buy-batch-modal';
import { EditLineItemsModal } from './_components/edit-line-items-modal';
import { OrderContext } from './_components/order-context';
import { StatusBar } from './_components/status-bar';
import { SyncStoresButton } from './_components/sync-stores-button';
import { useNavigate } from 'router';

export default function () {
  const batchModal = useDisclosure();
  const bulkEditDrawer = useDisclosure();
  const deleteOrdersModal = useDisclosure();
  const editLineItemsModal = useDisclosure();

  const navigate = useNavigate();

  const { notifySuccess } = useNotification();

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isGridLoading, setIsGridLoading] = useState<boolean>(true);
  const [selected, setSelected] = useState<'views' | 'status'>('status');

  const onViewSelect = () => {
    setSelected('views');
  };

  const onStatusSelect = () => {
    setSelected('status');
  };

  const gridRef = useRef<AgGridReact>(null);
  const tableId = 'orders-list';

  const deleteOrders = useDeleteOrders();

  const updateOrder = useUpdateOrder();
  const syncStores = useSyncStore();
  const getShopifyAuthUrl = useGetAuthUrl();
  const getAutomations = useGetAutomations();
  const runAutomations = useRunAutomations();
  const allStores = useGetQuery('/api/v3/stores', {});
  const getCustomStores = useGetQuery('/api/v3/stores', { params: { query: { include: 'custom-store' } } });
  const statusCount = useGetQuery('/api/v3/orders/count', {
    params: { query: { filterModel: '', groupBy: 'status' } },
  });

  const refreshData = () => {
    gridRef?.current?.api?.refreshServerSide();
    gridRef?.current?.api?.deselectAll();
    statusCount.refetch();
    allStores.refetch();
  };

  const handleDeleteOrders = async () => {
    const selectedOrderIds = getSelectedRows(gridRef).map((order) => order.id) || [];
    deleteOrders.mutate(
      { ordersId: selectedOrderIds },
      {
        onSuccess: () => {
          refreshData();
          notifySuccess('Your orders have been successfully deleted');
          deleteOrdersModal.onClose();
        },
      },
    );
  };

  const runOrderAutomations = () => {
    const selectedOrderIds = getSelectedRows(gridRef).map((order) => order.id) || [];
    if (!selectedOrderIds.length) return;

    const onSuccess = () => {
      notifySuccess(`Automations successfully ran for ${selectedOrderIds.length} orders`);
      refreshData();
    };

    runAutomations.mutate({ orderIds: selectedOrderIds }, { onSuccess });
  };

  const storeNeedsAuthAlertMarkup = allStores.data
    ?.filter((s) => s.needsAuth === true)
    ?.map((s) => (
      <Alert status="warning" gap="0" mb="2">
        <AlertIcon />
        <AlertTitle>Action Required</AlertTitle>
        <AlertDescription>
          Your {startCase(s.platform)} store <b>{s.name}</b> needs to be re-authenticated.
        </AlertDescription>
        <Button
          ml="auto"
          size="sm"
          variant="link"
          colorScheme="blue"
          gap="0"
          rightIcon={<FiExternalLink />}
          onClick={() => {
            const onSuccess = (data: { url: string }) => {
              window.open(data.url);
            };

            getShopifyAuthUrl.mutate({ id: s.id }, { onSuccess });
          }}
        >
          Authenticate
        </Button>
      </Alert>
    ));

  // Sync all stores on initial page load
  useEffect(() => {
    if (!allStores.data?.length) return;

    const stores = allStores.data
      .filter((s) => s.lastSyncAt && diff(s.lastSyncAt, new Date(), 'minutes') > 5)
      .map((store) => ({ id: store.id, platform: store.platform }));

    if (stores.length > 0) {
      syncStores.mutate(stores as any);
    }
  }, [allStores.data]);

  // Refreshes the grid after buying a label
  useEffect(() => {
    if (!batchModal.isOpen && gridRef?.current?.api?.getSelectedRows()) {
      refreshData();
    }
  }, [batchModal.isOpen]);

  const dataSource = useMemo(
    () =>
      getServerSideDatasource(getOrdersAxios, {
        q: searchQuery,
      }),
    [searchQuery],
  );

  const orderColumns = useMemo(
    () => columns({ onEdit: refreshData, stores: getCustomStores?.data as any }),
    [getCustomStores.data],
  );

  const defaultFilterModel = { status: { values: [OrderStatus.ReadyToShip] } };

  return (
    <OrderContext.Provider value={{ updateOrder }}>
      <PageHeader>
        <Heading as="h1" mr="auto">
          Orders
        </Heading>

        <HStack>
          <Button variant="outline" leftIcon={<LuImport />} onClick={() => navigate('/orders/import')}>
            Import
          </Button>
          <SyncStoresButton
            onSync={refreshData}
            stores={allStores.data}
            isLoading={syncStores.isLoading}
            disabled={isGridLoading || syncStores.isLoading}
          />
        </HStack>
      </PageHeader>
      <PageBody>
        <StatusBar
          data={statusCount.data}
          ref={gridRef}
          onStatusSelect={onStatusSelect}
          selected={selected}
        />
        {storeNeedsAuthAlertMarkup}
        <Box pt={5}>
          <CustomAgGrid
            onViewSelect={onViewSelect}
            setSearchQuery={setSearchQuery}
            selected={selected}
            viewEntity="orders"
            tableId={tableId}
            height="calc(100vh - 290px)"
            onGridReady={() => {
              setIsGridLoading(false);
              onStatusSelect();
              gridRef.current?.api?.setFilterModel(defaultFilterModel);
            }}
            ref={gridRef}
            columnDefs={orderColumns}
            dataSource={dataSource}
            hideCsvExport={false}
            actions={(selectedRows: Order[]) => {
              const eligibleOrders = selectedRows.filter((order) => order?.canBuyLabel);
              const hasEligibleOrders = eligibleOrders.length > 0;
              const hasSelectedRows = selectedRows.length > 0;
              const hasAutomations = (getAutomations?.data && getAutomations?.data?.length > 0) || false;
              const canDelete =
                selectedRows.every((order) => !(order.shipments?.length && order.shipments.length > 0)) &&
                hasSelectedRows;

              return [
                {
                  label: `Buy ${eligibleOrders.length} ${eligibleOrders.length > 1 ? 'labels' : 'label'}`,
                  action: batchModal.onOpen,
                  isHidden: !hasEligibleOrders,
                },
                {
                  action: bulkEditDrawer.onOpen,
                  isHidden: !hasSelectedRows,
                  label: `Edit ${selectedRows.length} ${selectedRows.length > 1 ? 'labels' : 'label'}`,
                },
                {
                  label: `Archive ${selectedRows.length} ${selectedRows.length > 1 ? 'labels' : 'label'}`,
                  action: deleteOrdersModal.onOpen,
                  isHidden: !canDelete,
                },
                {
                  label: 'Run automations',
                  action: runOrderAutomations,
                  isHidden: !hasAutomations || !hasSelectedRows,
                },
              ];
            }}
          />
        </Box>
      </PageBody>
      <ConfirmationModal
        header="Archive Order"
        description="Are you sure you want to archive the selected orders?"
        type="delete"
        isOpen={deleteOrdersModal.isOpen}
        onClose={deleteOrdersModal.onClose}
        onConfirm={handleDeleteOrders}
      />
      {batchModal.isOpen && (
        <BuyBatchModal
          onBuy={refreshData}
          isOpen={batchModal.isOpen}
          onClose={batchModal.onClose}
          orders={getSelectedRows(gridRef).filter((order) => order?.canBuyLabel)}
        />
      )}

      {bulkEditDrawer.isOpen && (
        <BulkEditDrawer
          onEdit={refreshData}
          isOpen={bulkEditDrawer.isOpen}
          onClose={bulkEditDrawer.onClose}
          orders={getSelectedRows(gridRef)}
        />
      )}

      {editLineItemsModal.isOpen && (
        <EditLineItemsModal orderId={getSelectedRows(gridRef)[0]?.id} {...editLineItemsModal} />
      )}
    </OrderContext.Provider>
  );
}
