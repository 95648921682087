import { Collapse, Icon, Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import { forwardRef, useState } from 'react';
import { FiCheck, FiEye, FiEyeOff, FiX } from 'react-icons/fi';
import ReactPasswordChecklist from 'react-password-checklist';

/**
 * @example Usage with react-hook-form:
 *
 * ```tsx
 *  <Controller
 *    name="password"
 *    control={form.control}
 *    render={({ field }) => <PasswordInput {...field} />}
 *  />
 * ```
 */
export const PasswordInput = forwardRef<any, InputProps>((props, ref) => {
  const { value = '', ...rest } = props;

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      <InputGroup mb="2">
        <Input
          ref={ref}
          id="password-input"
          placeholder=" "
          type={showPassword ? 'text' : 'password'}
          value={value}
          {...rest}
        />
        <InputRightElement cursor="pointer" color="muted" mr={2} onClick={() => setShowPassword(!showPassword)}>
          <Icon as={showPassword ? FiEyeOff : FiEye} />
        </InputRightElement>
      </InputGroup>
    </>
  );
});

/**
 * @example
 *
 * ```tsx
 *  const [isValidPassword, setIsValidPassword] = useState<boolean>(false);
 *  const password = form.watch('password'); // If using `react-hook-form`
 *
 *  <PasswordChecklist onChange={setValidPassword} value={password} />
 * ```
 */
export const PasswordChecklist = (props: { value: string; onChange: (isValid: boolean) => void }) => {
  const { value, onChange } = props;

  return (
    <Collapse in={!!value} animateOpacity style={{ width: '100%' }}>
      <ReactPasswordChecklist
        style={{ fontSize: '14px', marginRight: 'auto' }}
        rules={['minLength', 'specialChar', 'number', 'capital']}
        minLength={8}
        onChange={onChange}
        value={(value as string) || ''}
        iconComponents={{
          ValidIcon: <Icon as={FiCheck} textColor="green.500" mt={1.5} mr={1} />,
          InvalidIcon: <Icon as={FiX} textColor="gray.500" mt={1.5} mr={1} />,
        }}
      />
    </Collapse>
  );
};
