import { IStatusPanelParams } from 'ag-grid-community';
import { Button } from '@chakra-ui/react';
import { usePostMutation } from 'api/client';
import { parseFilterModel } from '.';
import { FiDownload } from 'react-icons/fi';

export const exportCsv = (props: IStatusPanelParams, entity?: string) => {
  if (!entity) return null;
  const { columnApi } = props;

  const columns = () =>
    columnApi
      .getColumnState()
      .filter((col) => col.hide === false && col.colId !== 'checkbox' && col.colId !== 'actions')
      .map((col) => {
        return {
          headerName: columnApi.getColumn(col?.colId)?.getColDef().headerName,
          field: col.colId,
        };
      });

  const getExport = usePostMutation('/api/v3/export/{entity}');

  return (
    <Button
      leftIcon={<FiDownload />}
      isLoading={getExport.isLoading}
      size={'xs'}
      variant={'outline'}
      mt={3}
      onClick={() =>
        getExport.mutate(
          {
            body: {
              filterModel: parseFilterModel(props.api.getFilterModel()),
              columns: columns(),
            },
            params: {
              path: {
                entity: entity,
              },
            },
          },
          {
            onSuccess: (result) => {
              window.open(result?.url, '_blank');
            },
          },
        )
      }
    >
      Export to CSV
    </Button>
  );
};
