import {
  Box,
  HStack,
  Icon,
  IconButton,
  IconButtonProps,
  Input,
  StackProps,
  Text,
  chakra,
  useDisclosure,
} from '@chakra-ui/react';
import { invalidateQueries, usePostMutation } from 'api/client';
import { CSSProperties, PropsWithChildren, createContext, useContext, useState } from 'react';
import { FiEdit2, FiPlus } from 'react-icons/fi';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { EditLocationModal } from './edit-location-modal';

/**
 * Context
 */

const SelectedNodeContext = createContext<{
  selectedNodeId: number | null;
  setSelectedNodeId: (id: number | null) => void;
}>({
  selectedNodeId: null,
  setSelectedNodeId: () => {},
});

export const SelectedNodeProvider = ({ children }: PropsWithChildren) => {
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);

  return (
    <SelectedNodeContext.Provider value={{ selectedNodeId, setSelectedNodeId }}>
      {children}
    </SelectedNodeContext.Provider>
  );
};

export const useSelectedNode = () => useContext(SelectedNodeContext);

type Node = { id: number; name: string; children: Node[]; inventories: any[]; originAddress: { id: string } };

export const TreeNode = ({
  node,
  level,
  onSelect,
}: {
  node: Node;
  level: number;
  onSelect?: (input: Node) => void;
}) => {
  const { selectedNodeId, setSelectedNodeId } = useSelectedNode();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showIcons, setShowIcons] = useState<boolean>(false);
  const [showAddNewLocationNode, setShowAddNewLocationNode] = useState<boolean>(false);

  const editLocationModal = useDisclosure();

  const addLocation = usePostMutation('/api/v3/locations');

  const isSelected = selectedNodeId === node.id;

  const handleClick = () => {
    setIsOpen(!isOpen);
    setSelectedNodeId(node.id);
    onSelect?.(node);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowAddNewLocationNode(false);
    const value = (e.currentTarget[0] as HTMLInputElement).value;

    addLocation.mutate(
      {
        body: {
          name: value,
          parentId: node.id,
          type: '',
          originAddressId: node.originAddress.id,
        },
      },
      {
        onSuccess: () => {
          invalidateQueries('/api/v3/locations');
        },
      },
    );
  };

  const styles: CSSProperties = {};
  const item: StackProps = {
    p: 1.5,
    mt: 0.5,
    rounded: 'lg',
    cursor: 'pointer',
    onClick: handleClick,
    _hover: { bg: 'zinc.100' },
    paddingLeft: level === 0 ? '2' : `${level * 20}px`,
    ...(isSelected && {
      bg: 'zinc.50',
      fontWeight: 'bold',
    }),
  };

  const actionButton: Partial<IconButtonProps> = {
    h: '5',
    size: 'xs',
    _hover: { color: 'zinc.900', bg: 'zinc.200' },
    color: 'zinc.400',
  };

  return (
    <div style={styles}>
      <chakra.details id={`${node.id}`}>
        <chakra.summary onMouseEnter={() => setShowIcons(true)} onMouseLeave={() => setShowIcons(false)}>
          <HStack spacing={0.5} {...item}>
            <Icon
              size={20}
              color="gray"
              style={{
                transform: isOpen ? 'rotate(90deg)' : '',
                transition: 'transform 150ms ease',
              }}
              as={MdKeyboardArrowRight}
            />

            <Text pr={2}>{node.name}</Text>
            {showIcons && (
              <IconButton
                {...actionButton}
                aria-label="Edit location"
                icon={<Icon as={FiEdit2} />}
                onClick={(e) => {
                  e.stopPropagation();
                  editLocationModal.onOpen();
                }}
              />
            )}
            {showIcons && (
              <IconButton
                {...actionButton}
                aria-label="Add new location"
                icon={<Icon as={FiPlus} />}
                onClick={(e) => {
                  if (!isOpen) document.getElementById(`${node.id}`)?.toggleAttribute('open');
                  if (isOpen) e.stopPropagation();
                  setShowAddNewLocationNode(true);
                }}
              />
            )}
          </HStack>
          <EditLocationModal {...editLocationModal} location={node} />
        </chakra.summary>

        {showAddNewLocationNode && (
          <form onSubmit={onSubmit}>
            <Input
              autoFocus={showAddNewLocationNode}
              onBlur={() => setShowAddNewLocationNode(false)}
              variant={'ghost'}
              placeholder="Add new location"
              size="xs"
              w={200}
              paddingLeft={`${(level + 2) * 20}px`}
            />
          </form>
        )}

        <div>
          {node.children &&
            node.children.length > 0 &&
            node.children.map((child) => (
              <TreeNode onSelect={onSelect} key={child.id} node={child} level={level + 1} />
            ))}

          {node.inventories && (
            <Box pl={1}>
              {node.inventories.map((i, index) => (
                <HStack
                  {...item}
                  cursor="default"
                  key={index}
                  color="muted"
                  bg="transparent"
                  fontWeight="normal"
                  gap={1}
                  paddingLeft={`${(level + 1) * 20}px`}
                >
                  <Text color={i.availableQuantity > 0 ? 'green.600' : 'red.600'}>
                    {i.availableQuantity || 0}
                  </Text>
                  <Text color="muted" ml={1}>
                    {i.product?.name}
                  </Text>
                </HStack>
              ))}
            </Box>
          )}
        </div>
      </chakra.details>
    </div>
  );
};

export function Tree({ data, onSelect }: { data: Node[]; onSelect?: (input: Node) => void }) {
  return (
    <SelectedNodeProvider>
      {data.map((node, index) => (
        <TreeNode onSelect={onSelect} key={index} node={node} level={0} />
      ))}
    </SelectedNodeProvider>
  );
}
